.odep-e-range-slider {
  position: relative;
  display: block;
  padding: 10px;

  &__slider {
    position: relative;
    height: 16px;
  }

  &__input {
    position: relative;
    width: 100%;
    min-height: 16px;
    padding: 0 !important;
    border: none !important;
    margin: 0;
    background: transparent;
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: $odep-color__white;
      box-shadow: none;
      cursor: pointer;
    }

    &::-ms-track {
      color: transparent;
      background: transparent;
      opacity: 0;
    }

    &::-ms-fill-lower {
      display: none;
      color: transparent;
      background: transparent;
      opacity: 0;
    }

    &::-ms-fill-upper {
      color: transparent;
      background: transparent;
      opacity: 0;
    }

    &::-ms-tooltip {
      display: none;
    }

    &::-webkit-slider-thumb {
      position: relative;
      z-index: 2;
      width: 16px;
      height: 16px;
      opacity: 0;
      cursor: pointer;
      -webkit-appearance: none;
    }

    &::-moz-range-thumb {
      position: relative;
      z-index: 2;
      width: 16px;
      height: 16px;
      opacity: 0;
      cursor: pointer;
      -webkit-appearance: none;
    }

    &::-ms-thumb {
      position: relative;
      z-index: 2;
      width: 16px;
      height: 16px;
      opacity: 0;
      cursor: pointer;
      -webkit-appearance: none;
    }
  }

  &__progress {
    position: absolute;
    top: 7px;
    left: 0;
    display: block;
    height: 4px;
    border-radius: 4px;
    background: $odep-color__green-300;
    pointer-events: none;
  }

  &__thumb {
    position: absolute;
    z-index: 2;
    top: 0;
    transform: translateX(-50%);
    display: block;
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 100%;
    background: $odep-color__white;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    pointer-events: none;

    :hover > & {
      opacity: 1;
    }
  }
}
