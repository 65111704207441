.odep-m-podcast-teaser {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 $odep-color__black-200;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 32px 64px rgba(0, 0, 0, 0.08), 0 16px 32px rgba(0, 0, 0, 0.08), 0 8px 16px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 $odep-color__black-200;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 16px;
    background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  }

  &__title {
    @extend %odep-type-750;
    @extend %odep-type-bold;

    margin: 0;
    color: $odep-color__white;
  }

  &__play-icon {
    position: absolute;
    top: calc(50% - 37px);
    left: calc(50% - 37px);
    width: 74px;
    height: 74px;
    padding: 20px;
    border-radius: 100%;
    background: rgba(73, 101, 76, 0.8);
    transition: background 0.3s ease-in-out, stroke 0.3s ease-in-out;
    stroke: $odep-color__white;

    .odep-m-podcast-teaser:hover & {
      background: rgba($color: $odep-color__white, $alpha: 0.8);
      stroke: $odep-color__green-500;
    }
  }
}
