.odep-m-audio-player-track {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  user-select: none;

  &:hover,
  &.odep-state-active {
    background: rgba(255, 255, 255, 0.1);
  }

  &__cover-image-box {
    flex-basis: 80px;
    margin-right: 24px;
  }

  &__cover-image {
    overflow: hidden;
    border-radius: 4px;
  }

  &__information-box {
    flex-grow: 1;
  }

  &__title {
    @extend %odep-type-800;
    @extend %odep-type-bold;

    margin: 0 0 4px;
  }

  &__description {
    margin: 0;
  }
}
