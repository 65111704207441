.odep-m-header {
  padding-top: 20px;

  @include breakpoint($mq-2-and-up) {
    padding-top: 32px;
  }

  &__content {
    @extend %odep-grid-container;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-link {
    padding: 8px;
    border-radius: 4px;
    color: $odep-color__white;
    text-decoration: none;
    line-height: 1;
    transition: background 0.3s ease-in-out;

    @include odep-focus-ring(10px, 4px, $odep-color__white);

    &:hover {
      background: rgba($color: $odep-color__white, $alpha: 0.1);
    }
  }

  &__logo {
    width: 120px;
    height: auto;
    fill: currentColor;
    stroke: none;

    @include breakpoint($mq-2-and-up) {
      width: 140px;
    }
  }

  &__contact-link {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: $odep-color__white;
    text-decoration: none;
    line-height: 1;
    transition: background 0.3s ease-in-out;

    @include odep-focus-ring(4px, 4px, $odep-color__white);

    &:hover {
      background: rgba($color: $odep-color__white, $alpha: 0.1);
    }
  }

  &__contact-icon {
    width: 24px;
    height: 24px;
    fill: none;
    stroke: currentColor;
  }
}
