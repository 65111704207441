.odep-e-play-toggle {
  display: block;

  &__btn {
    width: 50px;
    height: 50px;
    border: 2px solid $odep-color__white;
    border-radius: 100%;
    color: $odep-color__white;
    background: transparent;
    cursor: pointer;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;

    &:hover {
      color: $odep-color__green-500;
      background: $odep-color__white;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    stroke: currentColor;
  }
}
