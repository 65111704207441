*,
:before,
:after {
  box-sizing: border-box;
}

body {
  @extend %odep-type-500;

  margin: 0;
  color: $odep-color__white;
  background: radial-gradient(50% 50% at 50% 50%, $odep-color__green-500 0%, $odep-color__green-550 100%);
  font-size: 16px;
}

.odep-m-page {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.odep-m-page-main {
  position: relative;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;

  &--centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
