.odep-e-lazy-image {
  position: relative;
  display: inline-block;
  background: $odep-color__green-500;

  &.odep-state-initialized {
    animation: odep-placeholder-animation 3s ease 1s infinite;
  }

  &[has-error],
  &[loaded] {
    background: transparent;
    animation: none;
  }

  &[aspect-ratio] {
    display: block;
    overflow: hidden;
  }

  &[aspect-ratio]:not([aspect-ratio="cover"]):not([aspect-ratio="contain"]) {
    height: 0;
  }

  &[aspect-ratio="21:9"] {
    padding-bottom: percentage(9 / 21);
  }

  &[aspect-ratio="16:9"] {
    padding-bottom: percentage(9 / 16);
  }

  &[aspect-ratio="4:3"] {
    padding-bottom: percentage(3 / 4);
  }

  &[aspect-ratio="3:2"] {
    padding-bottom: percentage(2 / 3);
  }

  &[aspect-ratio="1:1"] {
    padding-bottom: 100%;
  }

  &[aspect-ratio="2:3"] {
    padding-bottom: percentage(3 / 2);
  }

  &[aspect-ratio="4:5"] {
    padding-bottom: percentage(5 / 4);
  }

  &.odep-state-loaded {
    background: transparent;
  }

  &--placeholder {
    background: $odep-color__green-500;
    animation: none;
  }

  &__placeholder-content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__placeholder-icon {
    width: 20%;
    height: auto;
  }
}
