/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../../resources/fonts/montserrat/montserrat-v15-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../resources/fonts/montserrat/montserrat-v15-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../resources/fonts/montserrat/montserrat-v15-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../resources/fonts/montserrat/montserrat-v15-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../../resources/fonts/montserrat/montserrat-v15-latin-regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* montserrat-italic - latin */
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../../resources/fonts/montserrat/montserrat-v15-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../resources/fonts/montserrat/montserrat-v15-latin-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../resources/fonts/montserrat/montserrat-v15-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../resources/fonts/montserrat/montserrat-v15-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("../../resources/fonts/montserrat/montserrat-v15-latin-italic.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* montserrat-800 - latin */
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("../../resources/fonts/montserrat/montserrat-v15-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../resources/fonts/montserrat/montserrat-v15-latin-800.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../resources/fonts/montserrat/montserrat-v15-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../resources/fonts/montserrat/montserrat-v15-latin-800.woff") format("woff"),
    /* Modern Browsers */ url("../../resources/fonts/montserrat/montserrat-v15-latin-800.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* montserrat-800italic - latin */
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: italic;
  font-display: swap;
  src: url("../../resources/fonts/montserrat/montserrat-v15-latin-800italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../resources/fonts/montserrat/montserrat-v15-latin-800italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../resources/fonts/montserrat/montserrat-v15-latin-800italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../resources/fonts/montserrat/montserrat-v15-latin-800italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../../resources/fonts/montserrat/montserrat-v15-latin-800italic.ttf") format("truetype"); /* Safari, Android, iOS */
}

$odep-typo__montserrat: "Montserrat", sans-serif;
