.odep-m-podcast-teaser-group {
  @extend %odep-grid-container;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 84px;
  margin-bottom: 84px;
  gap: 0 30px;

  @include breakpoint($mq-2-and-up) {
    grid-template-columns: repeat(12, 1fr);
    margin-top: 100px;
    margin-bottom: 100px;
  }

  &__headline,
  &__text,
  &__teasers {
    grid-column: span 2;

    @include breakpoint($mq-2-and-up) {
      grid-column: span 12;
    }

    @include breakpoint($mq-3-and-up) {
      grid-column: 2 / span 10;
    }

    @include breakpoint($mq-4-and-up) {
      grid-column: 3 / span 8;
    }
  }

  &__headline {
    @extend %odep-type-850;
    @extend %odep-type-bold;

    margin: 0 0 16px;
    text-align: center;
  }

  &__text {
    margin: 0 0 48px;
    text-align: center;
  }

  &__teasers {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;

    @include breakpoint($mq-2-and-up) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
