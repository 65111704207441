.odep-m-audio-player {
  display: block;
  min-height: 110px;

  @include breakpoint($mq-2-and-up) {
    min-height: 118px;
  }

  &__seeker-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @include breakpoint($mq-2-and-up) {
      margin-bottom: 32px;
    }
  }

  &__seeker-label {
    @extend %odep-type-400;

    display: block;
    width: 45px;
    margin: 0;
    text-align: center;
  }

  &__seeker-slider {
    flex-grow: 1;
    margin: 0 8px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
