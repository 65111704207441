// LSG-Color-Group
// Group-Name: Black/White
$odep-color__white: #fff;
$odep-color__black-500: #fafafa;
$odep-color__black-100: #f0f0f0;
$odep-color__black-200: #dedede;
$odep-color__black-300: #d0d0d0;
$odep-color__black-350: #9d9d9d;
$odep-color__black-400: #858585;
$odep-color__black-500: #545454;
$odep-color__black-600: #404040;
$odep-color__black-700: #353535;
$odep-color__black-800: #242424;
$odep-color__black-900: #000;

// LSG-Color-Group
// Group-Name: Green
$odep-color__green-100: #dbe0db;
$odep-color__green-200: #b6c1b7;
$odep-color__green-300: #92a394;
$odep-color__green-400: #6d8470;
$odep-color__green-450: #5b745e;
$odep-color__green-500: #49654c;
$odep-color__green-550: #425b44;
$odep-color__green-600: #3a513d;
$odep-color__green-700: #2c3d2e;
$odep-color__green-800: #1d281e;
$odep-color__green-900: #0f140f;
