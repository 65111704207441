.odep-m-footer {
  padding: 20px 0;

  &__content {
    @extend %odep-grid-container;

    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;

    @include breakpoint($mq-2-and-up) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__legal-nav-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    margin: 0 -8px 16px;
    list-style-type: none;

    @include breakpoint($mq-2-and-up) {
      margin: 0 -16px;
    }
  }

  &__legal-nav-item {
    padding: 0;
    margin: 0 8px;

    @include breakpoint($mq-2-and-up) {
      margin: 0 16px;
    }
  }

  &__legal-nav-link {
    color: $odep-color__white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
