@mixin odep-focus-ring(
  $offset: 5px,
  $borderRadius: 120px,
  $color: $odep-color__red-050,
  $focusClass: null,
  $borderWidth: 2px
) {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -$offset;
    left: -$offset;
    width: calc(100% + #{$offset * 2});
    height: calc(100% + #{$offset * 2});
    border: $borderWidth solid $color;
    border-radius: $borderRadius;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:focus {
    outline: none;
  }

  /* stylelint-disable */
  @if $focusClass {
    &#{$focusClass} {
      &:before {
        opacity: 1;
      }
    }
  } @else {
    .odep-h-keyboard-focus &:focus {
      &:before {
        opacity: 1;
      }
    }
  }
  /* stylelint-enable */
}

%odep-disable-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
