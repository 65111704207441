.odep-m-audio-player-component {
  @extend %odep-grid-container;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 84px;
  margin-bottom: 84px;
  gap: 24px 30px;

  @include breakpoint($mq-2-and-up) {
    grid-template-columns: repeat(12, 1fr);
    margin-top: 100px;
    margin-bottom: 100px;
  }

  &__cover-image-box {
    overflow: hidden;
    grid-column: span 2;
    border-radius: 8px;

    @include breakpoint($mq-2-and-up) {
      grid-column: 4 / span 6;
    }

    @include breakpoint($mq-3-and-up) {
      grid-column: 5 / span 4;
    }
  }

  &__info-box {
    grid-column: span 2;
    text-align: center;

    @include breakpoint($mq-2-and-up) {
      grid-column: 4 / span 6;
    }

    @include breakpoint($mq-3-and-up) {
      grid-column: 5 / span 4;
    }
  }

  &__title {
    @extend %odep-type-950;
    @extend %odep-type-bold;

    margin: 0 0 8px;
  }

  &__description {
    @extend %odep-type-550;
    @extend %odep-type-reg;

    margin: 0;
  }

  &__audio-player {
    grid-column: span 2;

    @include breakpoint($mq-2-and-up) {
      grid-column: 3 / span 8;
    }

    @include breakpoint($mq-3-and-up) {
      grid-column: 4 / span 6;
    }
  }

  &__tracks {
    grid-column: span 2;
    margin: -8px 0;
    margin-top: 40px;

    @include breakpoint($mq-2-and-up) {
      grid-column: 2 / span 10;
      margin-top: 80px;
    }
  }

  &__track {
    margin: 8px -16px;

    @include breakpoint($mq-3-and-up) {
      margin: 8px 0;
    }
  }

  .odep-m-audio-player-track__cover-image-box {
    @include breakpoint($mq-1-max) {
      display: none;
    }
  }
}
