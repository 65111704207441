@mixin smoothing {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

%odep-type-reg {
  font-weight: 400;
}

%odep-type-bold {
  font-weight: 800;
}

// LSG-Typography-Type
// Placeholder-Tag: p
// Placeholder-Word-Count: 8
%odep-type-950 {
  margin: 0 0 24px;
  font-family: $odep-typo__montserrat;
  font-size: 2.375rem; // 38px
  line-height: 1.33; // 51px
  letter-spacing: 0.15rem;
  @include smoothing();

  @include breakpoint($mq-4-and-up) {
    margin: 0 0 32px;
    font-size: 3rem; // 48px -> line-height: 64px;
  }
}

.odep-type-950 {
  @extend %odep-type-950;
}

// LSG-Typography-Type
// Placeholder-Tag: p
// Placeholder-Word-Count: 10
%odep-type-900 {
  margin: 0 0 16px;
  font-family: $odep-typo__montserrat;
  font-size: 2rem; // 32px
  line-height: 1.27; // 41px
  @include smoothing();

  @include breakpoint($mq-4-and-up) {
    margin: 0 0 32px;
    font-size: 2.75rem; // 44px -> line-height: 56px;
  }
}

.odep-type-900 {
  @extend %odep-type-900;
}

// LSG-Typography-Type
// Placeholder-Tag: p
// Placeholder-Word-Count: 15
%odep-type-850 {
  margin: 0 0 16px;
  font-family: $odep-typo__montserrat;
  font-size: 1.75rem; // 28px
  line-height: 1.35; // 38px
  @include smoothing();

  @include breakpoint($mq-4-and-up) {
    margin: 0 0 24px;
    font-size: 2.5rem; // 40px -> line-height: 54px;
  }
}

.odep-type-850 {
  @extend %odep-type-850;
}

// LSG-Typography-Type
// Placeholder-Tag: p
// Placeholder-Word-Count: 15
%odep-type-800 {
  margin: 0 0 16px;
  font-family: $odep-typo__montserrat;
  font-size: 1.5rem; // 24px
  line-height: 1.42; // 35px
  @include smoothing();

  @include breakpoint($mq-4-and-up) {
    margin: 0 0 24px;
    font-size: 1.75rem; // 28px -> line-height: 40px;
  }
}

.odep-type-800 {
  @extend %odep-type-800;
}

// LSG-Typography-Type
// Placeholder-Tag: p
// Placeholder-Word-Count: 15
%odep-type-750 {
  margin: 0 0 16px;
  font-family: $odep-typo__montserrat;
  font-size: 1.5rem; // 24px
  line-height: 1.33; // 32px
  @include smoothing();
}

.odep-type-750 {
  @extend %odep-type-750;
}

// LSG-Typography-Type
// Placeholder-Tag: p
// Placeholder-Word-Count: 15
%odep-type-700 {
  margin: 0 0 16px;
  font-family: $odep-typo__montserrat;
  font-size: 1.25rem; // 20px
  line-height: 1.4; // 28px
  @include smoothing();
}

.odep-type-700 {
  @extend %odep-type-700;
}

// LSG-Typography-Type
// Placeholder-Tag: p
// Placeholder-Word-Count: 100
%odep-type-550 {
  margin: 0;
  font-family: $odep-typo__montserrat;
  font-size: 1.125rem; // 18px
  line-height: 1.44; // 26px
  @include smoothing();
}

.odep-type-550 {
  @extend %odep-type-550;
}

// LSG-Typography-Type
// Placeholder-Tag: p
// Placeholder-Word-Count: 100
%odep-type-500 {
  margin: 0;
  font-family: $odep-typo__montserrat;
  font-size: 1rem; // 16px
  line-height: 1.5; // 24px
  @include smoothing();
}

.odep-type-500 {
  @extend %odep-type-500;
}

// LSG-Typography-Type
// Placeholder-Tag: p
// Placeholder-Word-Count: 100
%odep-type-400 {
  margin: 0;
  font-family: $odep-typo__montserrat;
  font-size: 0.875rem; // 14px
  line-height: 1.57; // 22px
  @include smoothing();
}

.odep-type-400 {
  @extend %odep-type-400;
}

// LSG-Typography-Type
// Placeholder-Tag: p
// Placeholder-Word-Count: 100
%odep-type-200 {
  margin: 0;
  font-family: $odep-typo__montserrat;
  font-size: 0.75rem; // 12px
  line-height: 1.66; // 20px
  @include smoothing();
}

.odep-type-200 {
  @extend %odep-type-200;
}
