.odep-e-icon {
  position: relative;
  display: inline-flex;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
  color: inherit;
  fill: currentColor;
}
